/* stylelint-disable scss/at-extend-no-missing-placeholder */
/* stylelint-disable scss/selector-no-union-class-name */
/* stylelint-disable selector-class-pattern */
/* stylelint-disable no-descending-specificity */
@import 'styles/mixins';
@import 'styles/variable';

@mixin containers {
  @extend .GlobalContainer;

  display: flex;
  max-width: 1146px; // $maxContentWidth;
  height: 100%;
}

.logo {
  width: 120px;
  height: 29px;

  @include sm {
    width: 150px;
    height: 40px;
  }
}

.header {
  position: relative;
  width: 100%;

  // height: 64px;
  margin-top: 0;
  background-color: #fff;

  // border-bottom: 1px solid #e5e6eb;
  // box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.05);

  @include msd {
    // height: 54px;
    position: sticky;
    top: 0;
    z-index: 30;
  }

  @include sm {
    // height: 54px;
  }
}

.header_h5 {
  line-height: 54px;
}

.headerPosition {
  position: sticky;
  top: 0;
  z-index: 10;
}

.headerContainer {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 12px 48px;
  margin: 0 auto;
  background-color: #fff;

  @include sm {
    &_defaultH5 {
      width: 100%;
      min-width: 100%;
      max-width: 100%;
    }
  }
}

@media screen and (max-width: 570px) {
  .headerContainer {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 58px;
    padding: 11px 12px 11px 16px;
  }
}

.headerContainer_h5 {
  padding-right: 20px !important;
  padding-left: 20px !important;
}

.unread {
  position: absolute;
  top: -4px;
  right: -18px;
  z-index: 2;
  padding: 1px 5px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  color: #fff;
  text-align: center;
  letter-spacing: 0.0075em;
  background: #f44336;
  border-radius: 15px;

  @include sm {
    word-break: keep-all;
  }
}

.unread_99 {
  right: -30px;
}

.headerLogo {
  display: flex;
  flex-direction: column;
  justify-content: center;

  a {
    @include sm {
      font-size: 0;
    }
  }

  .headerLogoTitle {
    display: flex;
    align-items: center;
  }

  .headerLogoImage {
    width: 100px;
    height: 29px;
    object-fit: contain;
  }
}

.headerLinksWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 40px;
  margin-left: 20px;
  overflow: hidden;

  @include msd {
    // display: none;
  }
}

.headerLinksList {
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
  align-items: center;
  height: 100%;

  @include sm {
    // display: none;
  }
}

.headerLink {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 0 10px;
  letter-spacing: 0.05em;
  list-style-type: none;
  cursor: pointer;

  @include sm {
    display: none;

    &:last-child {
      display: flex;
    }
  }

  &:last-child {
    padding-right: 0;
  }

  .headerLinkItem,
  .headerLinkIcon {
    &:hover {
      cursor: pointer;
    }
  }

  .headerLinkText {
    position: relative;
    display: inline-flex;
    height: 40px;
    padding: 0 12px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 40px;
    color: #000;
    text-align: center;
    white-space: nowrap;

    &.isActive {
      color: #1c70ee !important;
    }

    &:hover {
      color: #1c70ee !important;
      background: #f5f9ff !important;
      border-radius: 10px;
    }

    &:hover::after {
      transform: scaleX(1);
      transform-origin: bottom left;
    }
  }

  .headerRightLinkText {
    position: relative;
    padding: 0;

    &:hover {
      font-weight: 500;
      color: #000 !important;
      background: none !important;
    }
  }

  .headerLinkTextCurrentPage {
    position: relative;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 700 !important;
    color: #1c70ee !important;
    text-align: center;
    background: #f5f9ff;
    border-radius: 10px;
  }

  .headerLinkCurrentPage {
    color: #1c70ee;
    background: #f5f9ff;
    border-radius: 10px;

    // font-weight: 700 !important;

    >svg {
      margin-left: 2px;

      path {
        fill: #1c70ee;
      }
    }
  }

  .headerLinkLogin {
    padding-right: 0;
    padding-left: 0;
  }

  .headerLinkIcon {
    display: flex;

    span {
      margin-left: 8px;
    }
  }

  .countryItem {
    display: flex;
    align-items: center;
    height: 40px;
    padding: 12px 20px;
    background: #f8f9fa;
    border-radius: 10px;

    &:hover {
      background: #f4f5f6;
    }

    // .separator {
    //   padding: 0 8px;
    //   color: #e4e4e4;
    // }

    .label {
      position: relative;
      z-index: 1;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
      color: #000;
      text-align: center;
      text-transform: uppercase;
      letter-spacing: 0.105px;
      white-space: nowrap;
    }
  }
}

.headerLinkWrapper {
  display: flex;
  justify-content: flex-end !important;
  padding-top: 10px;

  &Extra {
    cursor: pointer;

    &Text {
      padding-bottom: 2px;
      margin-left: 20px;
      font-size: 11px !important;
      font-weight: normal;
      color: $darkgrey;
      border-bottom: 1px solid transparent;

      &:hover {
        border-bottom: 1px solid $darkgrey;
      }
    }
  }
}

.profile {
  &ProtectedWrapper {
    display: flex;
    align-items: center;
    width: 40px;
    cursor: pointer;

    .vipAvatar {
      position: relative;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 41px;
      height: 41px;
      margin-right: 15px;
      border: 1px solid #ffe1d0;
      border-radius: 50%;

      @media screen and (max-width: 570px) {
        width: 27px;
        height: 27px;
        margin-right: 3px;
      }
    }
  }

  &HasUnread {
    position: relative;

    &::after {
      position: absolute;
      top: 2px;
      right: 0;
      display: inline-block;
      width: 9px;
      height: 9px;
      content: ' ';
      background-color: #f75555;
      border: 1.5px solid white;
      border-radius: 50%;

      @media screen and (max-width: 570px) {
        top: 0;
        right: 0;
      }
    }
  }

  &Wrapper {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    &:hover {
      cursor: not-allowed;
    }
  }

  &Country {
    margin-right: 30px;
  }

  &Disabled {
    cursor: none;
  }

  &PlaceHolder {
    display: inline-block;
    width: 39px;
    height: 39px;
    border-radius: 50%;
    border-radius: 100px;

    &:hover {
      border-color: #e8e8ea;
    }
  }

  @media screen and (max-width: 570px) {
    &ProtectedWrapper {
      width: auto;

      &:hover {
        background-color: transparent !important;
      }
    }
  }
}

.mobileIconWrapper {
  display: none;

  .icon {
    margin: 0 0 0 12px;

    &:hover {
      cursor: pointer;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  @include msd {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
  }
}

.authUserMenu {
  .headerMenu {
    position: absolute;
    top: 9px;
    right: 4px;
    width: max-content !important;
    overflow: hidden;
    background-color: $white-3;
    border-radius: 12px;
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.04);

    @media screen and (max-width: 570px) {
      top: 8px;
      right: -42px;
    }
  }

  .headerMenuList {
    list-style-type: none;

    .headerMenuLink {
      display: flex;
      flex-direction: row;
      gap: 8px;
      align-items: center;
      padding: 16px 20px;
      cursor: pointer;

      // &:hover {
      //   cursor: pointer;
      // }
    }

    li {
      .linkText {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        color: #111212;
      }

      &:hover {
        background-color: #f5f9ff;
        border-radius: 10px;

        .linkText {
          color: #1c70ee;
        }
      }
    }
  }
}

.hotTag {
  position: absolute;
  top: 5px;
  right: -20px;
  padding: 1px 5px;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  color: white;
  background: #d2030f;
  border-radius: 8px;
}

.LandingHeaderContainer {
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100%;
  background: #fff;
}

.LandingHeader {
  max-width: 1366px;
  margin: 0 auto;

  .headerLogoImage {
    filter: drop-shadow(30px 40px #136fd3);
  }

  .header {
    background: #fff;
    border: none;
  }

  .headerLinkText {
    color: #111212;
  }

  .headerLink {
    color: #136fd3;
    background: #fff;
  }

  .startButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    min-width: 123px;
    height: 35px;
    border: 1.5px solid #136fd3;
    border-radius: 10px;
  }

  .headerLogo {
    .logo {
      width: auto;
      padding-top: 3px;

      a {
        display: flex;
        align-items: center;
        width: auto;
      }
    }
  }

  .countryItem {
    display: flex;
    gap: 5px;
    align-items: center;
    color: #353535;

    .label {
      position: relative;
      z-index: 1;
      background-color: #fff;
    }

    .object {
      filter: drop-shadow(#353535 20px 0);
      transform: translate(-20px, 0);
    }
  }
}

.unCompleted::after {
  position: absolute;
  top: 5px;
  right: -6px;
  display: inline-block;
  width: 6px;
  height: 6px;
  overflow: hidden;
  content: '';
  background-color: #f75555;

  // border: 1.5px solid white;
  border-radius: 50%;
}

.getStartedButton {
  display: flex;
  gap: 6px;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 12px 16px;
  color: #1c70ee;
  cursor: pointer;
  background-color: #fff;
  border: 1.2px solid #1c70ee;
  border-radius: 10px;

  span {
    font-size: 14px;
    font-weight: 800;
    color: #1c70ee;
  }

  &:hover {
    border: 1.2px solid #0d6eff;

    span {
      color: #0d6eff;
    }
  }
}

.headerLinkRightItem {
  height: 40px;
  padding: 0 6px;
  font-weight: 500;
  line-height: 20px;
  border-radius: 10px;

  @media (max-width: 576px) {
    padding: 0;
  }
}

.headerLinkRightText {
  height: 40px;
  padding: 0 8px;
  line-height: 40px;
  background: #f8f9fa;

  &:hover {
    background: #f4f5f6;
    border-radius: 12px;
  }
}

.locationIcon {
  width: 18px;
  height: 18px;
}

.employerAdv {
  width: 576px;
  padding-top: 5px;
  padding-bottom: 14px;
  border-radius: 8px;

  .mainBox {
    position: relative;
    display: flex;
    gap: 12px;
    width: 100%;
    height: 100%;
    padding: 12px 0 12px 12px;
    margin-top: -10px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08);

    .angle {
      position: absolute;
      top: -11px;
      left: 50%;
      transform: translateX(-50%);
    }

    >div {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 176px;
      height: 100%;
      max-height: 199px;
      cursor: pointer;
      border: 1px solid #fff;
      border-radius: 8px;

      a {
        display: block;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding-bottom: 17px;
      }

      .svgBox {
        position: relative;
        width: 100%;
      }

      .svgLine {
        position: absolute;
        top: 21px;
        left: 0;
        width: 100%;
        overflow: hidden;
      }

      .svg {
        position: relative;
        height: 100px;
        text-align: center;
        transform: translateY(-10px);
      }

      .svgPart1 {
        position: absolute;
        top: 32px;
        left: 52px;
        z-index: 2;
        transition: 0.3s;

        img {
          width: 74px;
          height: 54px;
        }
      }

      .svgPart2 {
        position: absolute;
        top: 52px;
        left: 28px;
        z-index: 1;
        transition: all 0.3s;

        img {
          width: 106px;
          height: 84px;
        }
      }

      &.efficiency {
        // background: linear-gradient(180deg, #ffe4a6 0%, #fffdfc 54.33%);
        background: linear-gradient(174deg, #80bbff 24.57%, #fafcff 95.29%);

        &:hover {
          // border: 1px solid #ffb201;

          // h5 {
          //   color: #2378e5;
          // }

          .svgPart1 {
            top: 26px;
          }

          .svgPart2 {
            top: 50px;
          }
        }
      }

      &.accuracy {
        // background: linear-gradient(180deg, #bde5ff 0%, #fffdfc 54.33%);
        background: linear-gradient(180deg, #c4fbff 0%, #f0feff 100%);

        &:hover {
          // border: 1px solid #3272f9;

          // h5 {
          //   color: #2378e5;
          // }

          .svgPart1 {
            top: 26px;
          }

          .svgPart2 {
            top: 50px;
          }
        }
      }

      &.quality {
        // background: linear-gradient(180deg, #bef997 0%, #fffdfc 54.33%);
        background: linear-gradient(180deg, #f9eaff 0%, #fdf8ff 100%);

        &:hover {
          // border: 1px solid #6dcf69;

          // h5 {
          //   color: #2378e5;
          // }

          .svgPart1 {
            top: 26px;
          }

          .svgPart2 {
            top: 50px;
          }
        }
      }

      h5 {
        z-index: 3;
        padding: 20px 8px 0;
        margin: 0;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        color: #121212;
      }

      p {
        z-index: 3;
        padding: 0 8px;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: #3b3c3c;
        text-align: center;
      }
    }
  }
}

.startedAdv {
  min-width: 280px;
  margin-top: -5px;
  border-radius: 8px;

  .loginBox {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    padding: 16px;
    margin-bottom: 4px;
    background: #FFF;
    border-radius: 8px;
    box-shadow: 0 0 10.8px 0 rgba(1, 37, 56, 0.14);

    .loginInfo {
      display: flex;
      gap: 12px;
      align-items: flex-start;

      .personIcon {
        display: flex;
        gap: 18.333px;
        align-items: center;
        justify-content: center;
        width: 44px;
        height: 44px;
        padding: 9.167px 11px;
        background: #DDD;
        border-radius: 183.333px;
      }

      .loginInfoRight {
        max-width: 248px;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        color: #121212;

        .notLogin {
          font-weight: 700;
          line-height: 24px;
          color: #000;
        }
      }
    }

    .signUp {
      color: #1C70EE;
      cursor: pointer;

      &:hover {
        color: #0D6EFF;
        text-decoration-line: underline;
        text-underline-offset: 4px;
      }
    }

    .loginBtn {
      display: flex;
      flex-direction: column;
      gap: 6px;
      align-items: center;
      align-self: stretch;
      justify-content: center;
      padding: 12px;
      overflow: hidden;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 12px;
      color: #FFF;
      cursor: pointer;
      background: #1C70EE;
      border-radius: 8px;
      box-shadow: 0 4px 6px 0 rgba(66, 81, 97, 0.18);

      &:hover {
        background: #0D6EFF;
      }
    }

    .loginBtnTxt {
      max-width: 261px;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .mainBox {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 20px 20px 8px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08);

    .angle {
      position: absolute;
      top: -11px;
      left: 50%;
      transform: translateX(-50%);
    }

    h5 {
      padding-bottom: 8px;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      color: #000;
    }

    .item {
      display: flex;
      align-items: center;
      padding: 12px 0;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      color: #111212;
      text-transform: capitalize;
      border-bottom: 1px solid #f6f6f6;

      span {
        position: relative;
        top: 4px;
        padding-right: 10px;
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }
}

.skeleton {
  width: 85px;
  height: 32px;
  background: #f2f3f5;
  animation: loading 2s ease infinite;
}

@keyframes loading {
  0% {
    background-image: linear-gradient(100deg, #eee 40%, #fff 50%, #eee 60%);
    background-position: 100% 50%;
    background-size: 300% 100%;
  }

  100% {
    background-image: linear-gradient(100deg, #eee 40%, #fff 50%, #eee 60%);
    background-position: 0 50%;
    background-size: 300% 100%;
  }
}

.headerFindJob {
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: transform 0.3s ease;

  // svg {
  //   transform: rotate(180deg);
  // }
}

.headerFindJob:hover {
  background: none !important;

  >svg {
    path {
      fill: #1c70ee;
    }
  }
}

.findJobDownMenu {
  :global {
    .ant-dropdown-menu {
      min-width: 242px;
      padding: 8px;
      margin-top: 10px !important;
      background: #fff;
      border-radius: 12px;
      box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.04);

      .ant-dropdown-menu-item {
        padding: 16px !important;

        .ant-dropdown-menu-title-content {
          a {
            width: 100%;
          }
        }
      }
    }
  }
}

.moreLinks {
  :global {
    .ant-dropdown-menu {
      width: 279px;
      padding: 8px;
      margin-top: 25px !important;
      background: #fff;
      border-radius: 12px;
      box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.04);

      .ant-dropdown-menu-item {
        padding: 18px 16px !important;

        .ant-dropdown-menu-title-content {
          a {
            width: 100%;
          }
        }
      }
    }
  }

  // .children {
  //   &_nav {
  //     display: flex;
  //     align-items: center;

  //     &_icon {
  //       display: flex;
  //       flex-direction: column;
  //       align-items: center;
  //       margin-right: 8px;
  //     }

  //     &_short {
  //       p {
  //         font-size: 12px;
  //         font-style: normal;
  //         font-weight: 400;
  //         line-height: normal;
  //         color: #7d7d7d;
  //       }
  //     }

  //     &_title {
  //       font-size: 16px;
  //       font-style: normal;
  //       font-weight: 500;
  //       line-height: 24px;
  //       color: #111212;
  //     }

  //     .childrenArrow {
  //       float: right;
  //       padding-left: 10px;
  //       margin-left: auto;
  //       opacity: 0;
  //       transition: opacity 0.3s ease;
  //     }

  //     .checkedIcon {
  //       display: none;
  //     }
  //   }
  // }

  // li {
  //   &:hover {
  //     background-color: #F5F9FF !important;
  //     border-radius: 10px !important;

  //     .children {
  //       &_nav {
  //         &_title {
  //           color: #1C70EE;
  //         }
  //       }

  //       // &_nav {
  //       //   .childrenArrow {
  //       //     opacity: 1;
  //       //   }
  //       // }
  //     }
  //   }
  // }
}

.findJobDownMenu {
  .children {
    &_nav {
      display: flex;
      align-items: center;

      &_icon {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 8px;

        .icon_hover {
          position: absolute;
          top: 0;
          right: 0;
          display: none;
        }
      }

      &_short {
        p {
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          color: #7d7d7d;
        }
      }

      &_title {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        color: #111212;
      }

      .childrenArrow {
        float: right;
        padding-left: 10px;
        margin-left: auto;
        opacity: 0;
        transition: opacity 0.3s ease;
      }

      .checkedIcon {
        display: none;
      }
    }
  }

  li {
    &:hover {
      background-color: #f5f9ff !important;
      border-radius: 10px !important;

      .icon_hover {
        display: inline-block;
      }

      .icon_default {
        visibility: hidden;
      }

      .children {
        &_nav {
          &_title {
            color: #1c70ee;
          }
        }

        // &_nav {
        //   .childrenArrow {
        //     opacity: 1;
        //   }
        // }
      }
    }
  }
}

.remoteMenu {
  li:nth-of-type(1) {
    border-radius: 4px;

    .checkedIcon {
      display: block;
    }

    &:hover {
      background: #f4f8fe !important;

      .childrenArrow {
        opacity: 0;
      }

      * {
        color: #2378e5;
      }
    }

    * {
      color: #1c70ee;
    }

    // .children {
    //   &_nav {
    //     &_icon {
    //       background: #2378e5;
    //     }
    //   }
    // }
  }
}

.onsiteMenu {
  li:nth-of-type(2) {
    color: #1c70ee;
    border-radius: 4px;

    .checkedIcon {
      display: block;
    }

    &:hover {
      background: #f4f8fe !important;

      .childrenArrow {
        opacity: 0;
      }

      * {
        color: #1c70ee;
      }
    }

    // .children {
    //   &_nav {
    //     &_icon {
    //       background: #2378e5;
    //     }
    //   }
    // }

    * {
      color: #1c70ee;
    }
  }
}

.web3Menu {
  li:nth-of-type(3) {
    color: #1c70ee;
    border-radius: 4px;

    .checkedIcon {
      display: block;
    }

    &:hover {
      background: #f4f8fe !important;

      .childrenArrow {
        opacity: 0;
      }

      * {
        color: #1c70ee;
      }
    }

    // .children {
    //   &_nav {
    //     &_icon {
    //       background: #2378e5;
    //     }
    //   }
    // }

    * {
      color: #1c70ee;
    }
  }
}

.recommendedMenu {
  li:nth-of-type(4) {
    color: #1c70ee;
    border-radius: 4px;

    .checkedIcon {
      display: block;
    }

    &:hover {
      background: #f4f8fe !important;

      .childrenArrow {
        opacity: 0;
      }

      * {
        color: #1c70ee;
      }
    }

    // .children {
    //   &_nav {
    //     &_icon {
    //       background: #2378e5;
    //     }
    //   }
    // }

    * {
      color: #1c70ee;
    }
  }
}

.web3LandingPageMenu {
  li:nth-of-type(1) {
    color: #1c70ee;
    border-radius: 4px;

    .checkedIcon {
      display: block;
    }

    &:hover {
      background: #f4f8fe !important;

      .childrenArrow {
        opacity: 0;
      }

      * {
        color: #1c70ee;
      }
    }

    * {
      color: #1c70ee;
    }
  }
}

.web3CompaniesMenu {
  li:nth-of-type(2) {
    color: #1c70ee;
    border-radius: 4px;

    .checkedIcon {
      display: block;
    }

    &:hover {
      background: #f4f8fe !important;

      .childrenArrow {
        opacity: 0;
      }

      * {
        color: #1c70ee;
      }
    }

    * {
      color: #1c70ee;
    }
  }
}

.web3GroupSvg {
  path {
    fill: #FF4119 !important;
  }
}

.notificationIconWrapper {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &[data-hasUnread='true'] {
    &::after {
      position: absolute;
      top: -3px;
      right: -3px;
      display: inline-block;
      width: 6px;
      height: 6px;
      content: ' ';
      background-color: #f75555;
      border-radius: 50%;
    }
  }
}

.chatButton {
  position: relative;
  display: flex;
  align-items: center;
  align-self: stretch;
  justify-content: center;
  padding: 5px 12px;
  margin-right: 12px;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  background: #f8f9fa;
  border-radius: 8px;

  @media screen and (max-width: 570px) {
    align-self: auto;
  }

  .unread {
    top: -7px;
    right: -4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 6px;
    margin: 0;
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
  }
}

.protectedAvatar {
  position: absolute;
  left: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 39px;
  height: 39px;
  background: transparent;
  border: 1px solid #ebeef0;
  border-radius: 50%;

  @media screen and (max-width: 570px) {
    width: 26px;
    height: 26px;
  }

  .profilePlaceHolder {
    width: 100%;
    height: 100%;
  }
}

.overlayClass {
  :global {
    .ant-popover-content {
      .ant-popover-inner {
        padding: 0;
      }
    }
  }
}

.completeProfileContent {
  position: relative;
  gap: 8px;
  align-items: center;
  justify-content: center;
  width: 260px;
  max-width: 260px;
  min-height: 114px;
  padding: 16px 12px 34px;
  background: linear-gradient(180deg, #fff 38.38%, #ddeaff 100%);
  border-radius: 8px;

  .completeProfileTitle {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    color: #111212;
  }

  .completeProfileTitleBold {
    font-weight: 600;
  }

  p {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    color: #111212;
  }

  p+svg {
    position: absolute;
    right: 0;
    bottom: 0;
  }
}